import React, { Fragment, useState } from 'react';
import CustomToolTip from './CustomToolTip';

interface IProps {
  id: string;
  audioUrl: string;
  representativeAudio?: boolean;
}

const AudioModal: React.FC<IProps> = ({ id, audioUrl, representativeAudio }) => {
  const [audioModalState, setAudioModalState] = useState(false);
  const handleAudioClick = (hopId: string) => {
    const audioContainer = document.getElementById(hopId);
    const audioElement: HTMLAudioElement | null = document.querySelector(`#audio${hopId}`);
    audioContainer?.setAttribute('style', audioModalState ? 'display:none' : 'display:block');
    if (audioModalState) {
      audioElement?.pause();
    }
    setAudioModalState(!audioModalState);
  };

  return (
    <div className="table-cell audioCell">
      {audioUrl && audioUrl.length && (
        <Fragment>
          <CustomToolTip
            tooltipVisible={representativeAudio === false} // null means something else
            message={'Voicemail May Be a Representative Sample of Campaign'}
          >
            <i
              className="fa-solid fa-volume-high"
              style={{ color: audioModalState ? '#479a6f' : 'initial' }}
              onClick={() => handleAudioClick(id)}
            />
          </CustomToolTip>
          <div id={id} className="audioModalContainer">
            <audio id={`audio${id}`} className="audioStyling" preload="none" controls>
              <source src={audioUrl} />
            </audio>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default AudioModal;
